/**
 *
 * MyFooter styles
 *
 */
#MyFooter {
  text-align: center;
  background: #333;
  color: #fff; }
  #MyFooter p {
    margin: 0; }
