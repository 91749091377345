/**
 *
 * GatewayShowPageView styles
 *
 */
#GatewayShowPageView {
  margin-top: 40px !important;
  margin-bottom: 40px !important; }
  #GatewayShowPageView .ant-card-head {
    background: #3F3F3F !important; }
  #GatewayShowPageView .ant-card-head-title {
    color: #fff !important;
    font-weight: lighter !important; }
  #GatewayShowPageView .row-margin {
    margin-top: 3% !important; }
  #GatewayShowPageView .label {
    margin-top: 8px !important; }
  #GatewayShowPageView .photo-btn:hover {
    color: #78b829 !important; }
  #GatewayShowPageView .save-btn {
    background: #78b829;
    border-color: #78b829;
    color: #fff;
    margin-top: 20px;
    height: 40px;
    display: inline-block;
    width: 30%; }
  #GatewayShowPageView .ant-spin-dot i {
    background-color: #78b829; }
  #GatewayShowPageView .gateway-photo {
    width: 30vh !important;
    height: 30vh !important; }
  #GatewayShowPageView .company-dropdown,
  #GatewayShowPageView .location-dropdown {
    width: 100% !important; }
  #GatewayShowPageView .photo-btn {
    margin-top: 2% !important; }
  #GatewayShowPageView .ant-select-selection {
    height: 40px; }
    #GatewayShowPageView .ant-select-selection:hover {
      border-color: #78b829; }
    #GatewayShowPageView .ant-select-selection .ant-select-selection__rendered {
      line-height: 38px; }
  #GatewayShowPageView .upload-btn-holder {
    text-align: center;
    margin-bottom: 40px; }
  #GatewayShowPageView .ant-btn:not([disabled]):hover {
    color: #78b829; }
  #GatewayShowPageView input {
    height: 40px; }
  #GatewayShowPageView button:focus {
    color: #78b829; }
  #GatewayShowPageView .submit-btn {
    margin-top: 20px !important;
    width: 20% !important;
    background-color: #78b829 !important;
    border-color: #78b829 !important;
    color: #fff !important; }
  #GatewayShowPageView .submit-btn:hover {
    background-color: #fff !important;
    color: #78b829 !important;
    border-color: #78b829 !important; }
