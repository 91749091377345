/**
 *
 * DevicesView styles
 *
 */
#DevicesView {
  margin-top: 30px !important; }
  #DevicesView .column-button {
    float: right; }
    @media only screen and (max-width: 480px) {
      #DevicesView .column-button {
        display: inline-block;
        float: none;
        margin-top: 20px; } }
  #DevicesView .column-button:hover {
    color: #78b829 !important;
    border-color: #78b829 !important; }
  @media only screen and (max-width: 768px) {
    #DevicesView .col-btn-holder {
      text-align: center; } }
  #DevicesView .ant-input:hover,
  #DevicesView .ant-input:focus {
    border-color: #78b829 !important;
    box-shadow: none; }
  #DevicesView .devices-table-container {
    margin-top: 2% !important; }
  #DevicesView .ant-table-row:hover td {
    background-color: #78b829 !important;
    color: #fff !important; }
  #DevicesView .ant-table-row:hover td a {
    color: #fff !important; }
  #DevicesView .ant-pagination-item-active {
    border-color: #78b829 !important; }
  #DevicesView .ant-pagination-item-active a {
    color: #78b829 !important; }
  #DevicesView .save-button {
    margin-top: 4% !important; }
  #DevicesView .save-button:hover {
    color: #78b829 !important;
    border-color: #fff !important; }
  #DevicesView .dev-holder .ant-col-lg-14 {
    float: right;
    max-width: 88px; }
  @media only screen and (max-width: 768px) {
    #DevicesView .dev-holder {
      margin-bottom: 30px; }
      #DevicesView .dev-holder .ant-col-lg-10 {
        float: left;
        max-width: 60%; } }
  #DevicesView .dev-holder .ant-input-group-addon,
  #DevicesView .dev-holder .ant-input,
  #DevicesView .dev-holder .column-button {
    height: 40px; }
  #DevicesView .ant-radio-button-wrapper {
    box-shadow: 0 0 0 2px rgba(120, 184, 41, 0.2) !important;
    outline-color: 0 0 0 2px rgba(120, 184, 41, 0.2) !important; }
