body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: sans-serif; }

html, #root {
  height: 100%; }

.cursor-pointer {
  cursor: pointer; }
