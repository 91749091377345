/**
 *
 * AddUserCard styles
 *
 */
#AddUserCard {
  margin-top: 40px !important; }
  #AddUserCard .login-form .add-form-button {
    background-color: #78b829 !important;
    color: #fff !important;
    border-color: #78b829 !important;
    width: 100% !important;
    height: 40px !important;
    font-size: 16px !important; }
  #AddUserCard .login-form .ant-input {
    height: 40px !important; }
  #AddUserCard .login-form .company-dropdown,
  #AddUserCard .login-form .role-dropdown {
    width: 100% !important; }
  #AddUserCard .ant-select-selection--single,
  #AddUserCard .ant-select-selection__rendered {
    height: 40px; }
  #AddUserCard .ant-select-selection:hover {
    border-color: #78b829; }
  #AddUserCard .ant-select-selection-selected-value {
    line-height: 36px; }
  #AddUserCard .roll-holder {
    position: relative; }
    #AddUserCard .roll-holder .roll-icon {
      position: absolute;
      top: 16px;
      left: 11px;
      z-index: 1;
      color: rgba(0, 0, 0, 0.25); }
    #AddUserCard .roll-holder .ant-select-selection {
      padding-left: 19px; }
