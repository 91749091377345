/**
 *
 * ChangePassword styles
 *
 */
#ChangePassword .login-form-button {
  background-color: #78b829 !important;
  border-color: #78b829 !important;
  width: 100% !important;
  font-size: 16px; }

#ChangePassword .ant-modal-body {
  padding: 0px !important; }

#ChangePassword .ant-input {
  height: 50px !important; }

#ChangePassword input:hover,
#ChangePassword input:focus {
  border-color: #78b829 !important;
  box-shadow: 0 0 0 2px rgba(120, 184, 41, 0) !important; }

#ChangePassword .submit-button-row {
  margin: 0px !important; }

.change-password-modal .ant-modal-footer {
  padding: 0px !important; }

.change-password-modal .ant-modal-close {
  outline: none; }
