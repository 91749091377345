/**
 *
 * EmptyData styles
 *
 */
#EmptyData {
  margin-top: 0 !important;
  margin-bottom: 5% !important; }
  #EmptyData .no-records-text {
    text-align: center !important; }
