/**
 *
 * SensoInfoView styles
 *
 */
#SensoInfoView {
  margin-top: 40px !important; }
  #SensoInfoView .ant-card-head {
    background: #3F3F3F !important; }
  #SensoInfoView .prefix {
    background-color: #eee;
    height: 40px;
    line-height: 40px;
    border: 1px solid lightgrey;
    padding-left: 10px;
    padding-right: 10px;
    float: left; }
  #SensoInfoView .field-with-prefix {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row; }
  #SensoInfoView .date-picker {
    width: 50% !important; }
  #SensoInfoView h1 {
    line-height: 32px;
    margin: 0 0 20px; }
  #SensoInfoView .ant-card-head-title {
    color: #fff !important; }
  #SensoInfoView .device-info-container {
    margin-top: 25px !important; }
    @media only screen and (max-width: 768px) {
      #SensoInfoView .device-info-container {
        margin-top: 0 !important; }
        #SensoInfoView .device-info-container .ant-col-lg-6 {
          margin-bottom: 0 !important; }
        #SensoInfoView .device-info-container .ant-col-lg-6 {
          margin-bottom: 20px !important; } }
    #SensoInfoView .device-info-container .ant-calendar-picker {
      width: 100%; }
  #SensoInfoView .realtime,
  #SensoInfoView .start-monitoring {
    margin-top: 40px !important; }
  #SensoInfoView .start-monitoring {
    margin-bottom: 40px !important; }
  #SensoInfoView .start-monitoring-button {
    color: #fff !important;
    background-color: #78b829 !important;
    border-color: #78b829 !important;
    width: 100% !important;
    height: 40px; }
  #SensoInfoView .goto-monitoring-button {
    color: #fff !important;
    background-color: grey !important;
    border-color: grey !important;
    width: 100% !important;
    height: 40px; }
  @media only screen and (max-width: 768px) {
    #SensoInfoView .device-info-row .ant-col-lg-5,
    #SensoInfoView .device-info-row .ant-col-lg-6 {
      width: 100%;
      margin-bottom: 20px;
      margin-right: 20px; } }
  @media only screen and (max-width: 480px) {
    #SensoInfoView .device-info-row .ant-col-lg-5,
    #SensoInfoView .device-info-row .ant-col-lg-6 {
      width: 100%;
      margin-right: 0; } }
  #SensoInfoView .device-info-row .ant-input-group-addon,
  #SensoInfoView .device-info-row .ant-input {
    height: 40px; }
    @media only screen and (max-width: 768px) {
      #SensoInfoView .device-info-row .ant-input-group-addon,
      #SensoInfoView .device-info-row .ant-input {
        min-width: 120px; } }
    @media only screen and (max-width: 480px) {
      #SensoInfoView .device-info-row .ant-input-group-addon,
      #SensoInfoView .device-info-row .ant-input {
        min-width: 100px; } }
