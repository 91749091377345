/**
 *
 * DeliveryTasksView styles
 *
 */
#DeliveryTasksView {
  margin-top: 4% !important; }
  #DeliveryTasksView .no-assigned-text {
    font-style: italic !important;
    text-align: center !important; }
  #DeliveryTasksView .ant-table-row:hover td {
    background-color: #78b829 !important;
    color: #fff !important; }
  #DeliveryTasksView .ant-pagination-item-active {
    border-color: #78b829 !important; }
  #DeliveryTasksView .ant-pagination-item-active a {
    color: #78b829 !important; }
