/**
 *
 * GatewayView styles
 *
 */
#GatewayView .sorting-icon {
  float: right;
  width: 12px;
  vertical-align: middle;
  cursor: pointer;
  opacity: 0.3;
  margin-top: 2px; }
  #GatewayView .sorting-icon:hover {
    opacity: 0.6; }

#GatewayView .gateway-name {
  padding: 30px 0; }
  @media only screen and (max-width: 480px) {
    #GatewayView .gateway-name {
      display: block !important;
      margin-bottom: 10px; }
      #GatewayView .gateway-name .field-holder {
        margin-bottom: 20px; } }
  #GatewayView .gateway-name .col-btn-holder {
    text-align: center; }
  #GatewayView .gateway-name .ant-input {
    height: 35px; }
  #GatewayView .gateway-name .column-button {
    height: 35px;
    float: right; }
    @media only screen and (max-width: 480px) {
      #GatewayView .gateway-name .column-button {
        display: inline-block;
        float: none; } }

#GatewayView .ant-input-group-addon {
  background-color: #78b829 !important;
  color: #fff !important;
  padding: 0 20px;
  min-width: 150px; }

#GatewayView .ant-pagination-item:focus a,
#GatewayView .ant-pagination-item:hover a {
  color: #78b829 !important; }

#GatewayView .ant-pagination-item-active {
  border-color: #78b829; }

#GatewayView .ant-pagination-item-active:focus a,
#GatewayView .ant-pagination-item-active:hover a,
#GatewayView .ant-pagination-item-active a {
  color: #78b829 !important; }

#GatewayView .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
#GatewayView .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
#GatewayView .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
#GatewayView .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background-color: #f9f9f9 !important; }

#GatewayView .ant-table-content {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  border-bottom: 0; }

#GatewayView .ant-table-thead > tr > th,
#GatewayView .ant-table-tbody > tr > td {
  padding: 17px 20px; }

#GatewayView .ant-btn:hover,
#GatewayView .ant-btn:focus {
  color: #78b829;
  border-color: #78b829; }

#GatewayView input:hover,
#GatewayView input:focus {
  border-color: #78b829 !important;
  box-shadow: none; }

#GatewayView .ant-switch {
  float: right; }

#GatewayView .ant-switch-checked {
  background: #78b829; }

#GatewayView .filter-icon {
  float: right;
  margin-top: 4px;
  color: #78b829;
  font-size: 17px; }
