/**
 *
 * ResetPasswordView styles
 *
 */
#ResetPasswordView {
  margin-top: 5%;
  margin-bottom: 17vh; }
  #ResetPasswordView .login-form-button {
    background-color: #78b829 !important;
    border-color: #78b829 !important;
    height: 50px;
    width: 100%; }
  #ResetPasswordView .recovery-text {
    text-align: center !important; }
  #ResetPasswordView .reset-password-card {
    display: flex;
    justify-content: center; }
  #ResetPasswordView .ant-input {
    height: 50px !important; }
  #ResetPasswordView .login-form {
    margin-left: 20%;
    width: 60%;
    -webkit-align-items: center;
    align-items: center;
    padding-top: 5%; }
    @media only screen and (max-width: 768px) {
      #ResetPasswordView .login-form {
        width: 100%;
        padding: 0;
        margin: 0; } }
    #ResetPasswordView .login-form .ant-form-item {
      width: 100%; }
  #ResetPasswordView .form-outer-holder {
    width: 80%;
    margin: 0 auto; }
