/**
 *
 * ForgotPassword styles
 *
 */
#ForgotPassword .login-form-button {
  background-color: #78b829 !important;
  border-color: #78b829 !important;
  width: 100%;
  height: 40px; }

#ForgotPassword .ant-input {
  height: 50px !important; }

#ForgotPassword .ant-form-item {
  width: 100%; }

#ForgotPassword input:hover,
#ForgotPassword input:focus {
  border-color: #78b829 !important;
  box-shadow: 0 0 0 2px rgba(120, 184, 41, 0) !important; }
