/**
 *
 * LocationView styles
 *
 */
#LocationView {
  margin-top: 30px !important; }
  #LocationView .add-location-button {
    background-color: transparent;
    color: #78b829 !important;
    border: 0;
    float: right !important;
    font-size: 28px;
    line-height: 21px;
    height: 30px;
    box-shadow: 0 0 0 2px rgba(120, 184, 41, 0);
    padding-right: 0; }
  #LocationView h2 {
    margin-bottom: 0; }
    @media only screen and (max-width: 480px) {
      #LocationView h2 {
        float: left; } }
