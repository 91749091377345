/**
 *
 * CompanyView styles
 *
 */
#CompanyView {
  margin-top: 30px !important; }
  #CompanyView .add-company-button {
    background-color: transparent;
    color: #78b829 !important;
    border: 0;
    float: right !important;
    font-size: 28px;
    line-height: 21px;
    height: 30px;
    box-shadow: 0 0 0 2px rgba(120, 184, 41, 0);
    padding-right: 0; }
  #CompanyView .companies-table {
    margin-top: 40px !important; }
    #CompanyView .companies-table .table-responsive .table td {
      cursor: inherit; }
  #CompanyView .ant-table-row:hover td {
    background-color: #78b829 !important;
    color: #fff !important; }
  #CompanyView .ant-pagination-item-active {
    border-color: #78b829 !important; }
  #CompanyView .ant-pagination-item-active a {
    color: #78b829 !important; }
  #CompanyView h2 {
    float: left;
    line-height: 40px;
    margin: 0; }
