body {
  height: 100%;
  width: 100%;
  background-color: #f2f3f8 !important; }

.layout {
  background-color: #fff !important; }

.app-content {
  padding: 0px 50px !important;
  min-height: 81vh !important; }
  @media only screen and (max-width: 480px) {
    .app-content {
      padding: 0 30px !important; } }
  .app-content .ant-card-head-title {
    font-weight: bold; }
  .app-content .ant-btn-primary {
    min-width: 120px; }
  .app-content .ant-btn:hover,
  .app-content .ant-btn:focus {
    color: #fff;
    border-color: #78b829; }
  .app-content .column-button:hover,
  .app-content .column-button:focus {
    border-color: #78b829;
    color: #78b829; }
  .app-content .ant-radio-button-wrapper-checked:hover {
    box-shadow: -1px 0 0 0 #78b829; }
  .app-content .ant-radio-button-wrapper-checked {
    box-shadow: -1px 0 0 0 #78b829; }
  .app-content .ant-select-focused .ant-select-selection,
  .app-content .ant-select-selection:focus,
  .app-content .ant-select-seleciotn:active,
  .app-content .ant-input-number-focused {
    border-color: #78b829;
    box-shadow: 0 0 0 2px rgba(120, 184, 41, 0.2); }
  .app-content input:hover,
  .app-content input:focus {
    border-color: #78b829 !important;
    box-shadow: 0 0 0 2px rgba(120, 184, 41, 0) !important; }
  .app-content .table-responsive {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    margin-bottom: 40px; }
    .app-content .table-responsive .table {
      margin-bottom: 0; }
      .app-content .table-responsive .table thead th {
        border-top: 0;
        background: #fafafa;
        border-bottom: 1px; }
      .app-content .table-responsive .table tr:hover {
        background: #f9f9f9; }
    .app-content .table-responsive .table th,
    .app-content .table-responsive .table td {
      padding: 15px 25px; }
    .app-content .table-responsive .table td {
      color: rgba(0, 0, 0, 0.6);
      cursor: pointer;
      vertical-align: middle; }
  .app-content .pagination {
    text-align: center;
    margin-bottom: 40px;
    display: block; }
    .app-content .pagination li {
      display: inline-block; }
    .app-content .pagination a {
      color: #78b829; }
  @media only screen and (max-width: 480px) {
    .app-content h2 {
      font-size: 20px;
      margin-top: 4px !important; } }
  .app-content .ant-form label {
    margin-bottom: 10px;
    display: block;
    font-size: 16px; }
  .app-content .device-input,
  .app-content .gateway-input {
    max-width: 100%; }
    .app-content .device-input .ant-input-group-addon,
    .app-content .device-input .ant-input,
    .app-content .gateway-input .ant-input-group-addon,
    .app-content .gateway-input .ant-input {
      height: 35px;
      min-width: 155px; }
      @media only screen and (max-width: 768px) {
        .app-content .device-input .ant-input-group-addon,
        .app-content .device-input .ant-input,
        .app-content .gateway-input .ant-input-group-addon,
        .app-content .gateway-input .ant-input {
          min-width: 221px; } }
      @media only screen and (max-width: 480px) {
        .app-content .device-input .ant-input-group-addon,
        .app-content .device-input .ant-input,
        .app-content .gateway-input .ant-input-group-addon,
        .app-content .gateway-input .ant-input {
          min-width: 117px; } }
      @media only screen and (max-width: 320px) {
        .app-content .device-input .ant-input-group-addon,
        .app-content .device-input .ant-input,
        .app-content .gateway-input .ant-input-group-addon,
        .app-content .gateway-input .ant-input {
          min-width: 85px; } }
    .app-content .device-input .ant-input-group-addon,
    .app-content .gateway-input .ant-input-group-addon {
      background-color: #78b829;
      color: #fff; }
  .app-content table .anticon {
    cursor: pointer !important; }
  .app-content #UserView .device-input .ant-input-group-addon {
    min-width: auto;
    background: #fff;
    color: rgba(0, 0, 0, 0.6); }
  .app-content ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #c5bfbf; }
  .app-content ::-moz-placeholder {
    /* Firefox 19+ */
    color: #c5bfbf; }
  .app-content :-ms-input-placeholder {
    /* IE 10+ */
    color: #c5bfbf; }
  .app-content :-moz-placeholder {
    /* Firefox 18- */
    color: #c5bfbf; }
  .app-content .ant-pagination {
    text-align: center;
    margin-bottom: 40px; }
    .app-content .ant-pagination li {
      display: inline-block; }
      .app-content .ant-pagination li:hover {
        border-color: #78b829;
        color: #78b829; }
        .app-content .ant-pagination li:hover a {
          color: #78b829; }
    .app-content .ant-pagination .ant-pagination-item-link {
      line-height: 26px; }
  .app-content .ant-pagination-item:focus,
  .app-content .ant-pagination-item:hover {
    color: #78b829 !important;
    border-color: #78b829 !important; }
  .app-content .ant-pagination-item:focus a {
    color: #78b829; }

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-checked .ant-checkbox-inner {
  border-color: #78b829 !important; }

.ant-checkbox-checked .ant-checkbox-inner {
  background: #78b829 !important; }

.ant-calendar-selected-date .ant-calendar-date,
.ant-calendar-selected-start-date .ant-calendar-date,
.ant-calendar-selected-end-date .ant-calendar-date,
.ant-calendar .ant-calendar-ok-btn,
.ant-calendar-today .ant-calendar-date {
  background: #78b829 !important;
  border-color: #78b829 !important;
  color: #fff !important; }

.ant-modal-close {
  outline: none !important; }

.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover,
.active-route {
  color: #78b829 !important; }

#address:focus {
  box-shadow: 0 0 0 2px rgba(120, 184, 41, 0) !important; }

.ant-modal-content input,
.ant-modal-content .ant-btn-primary {
  height: 40px; }

.ant-modal-content .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #78b829 !important;
  box-shadow: 0 0 0 2px rgba(120, 184, 41, 0) !important; }

.ant-modal-content input:focus {
  border-color: #78b829 !important;
  box-shadow: 0 0 0 2px rgba(120, 184, 41, 0) !important; }

.ant-modal-content .ant-btn-primary {
  background: #78b829;
  border-color: #78b829; }

.ant-modal-content .ant-btn-primary:hover,
.ant-modal-content .ant-btn-primary:focus {
  background: #78b829;
  border-color: #78b829; }

.ant-form-explain {
  margin-top: 6px !important; }
