/**
 *
 * DashboardDevicesView styles
 *
 */
#DashboardDevicesView .sorting-icon {
  float: right;
  width: 12px;
  vertical-align: middle;
  cursor: pointer;
  opacity: 0.3;
  margin-top: 2px; }
  #DashboardDevicesView .sorting-icon:hover {
    opacity: 0.6; }

#DashboardDevicesView .asset-type-dropdown {
  height: 40px;
  width: 100%;
  margin-bottom: 30px; }
  #DashboardDevicesView .asset-type-dropdown .ant-select-selection {
    height: 40px; }
    #DashboardDevicesView .asset-type-dropdown .ant-select-selection:hover {
      border-color: #78b829; }
  #DashboardDevicesView .asset-type-dropdown .ant-select-selection-selected-value {
    line-height: 38px; }

#DashboardDevicesView .ant-table-content {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  border-bottom: 0; }

#DashboardDevicesView .radio-buttons {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

#DashboardDevicesView .device {
  margin-bottom: 30px !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important; }
  #DashboardDevicesView .device .column-button {
    height: 35px;
    float: right; }
    @media only screen and (max-width: 480px) {
      #DashboardDevicesView .device .column-button {
        float: none;
        display: inline-block; } }
  @media only screen and (max-width: 480px) {
    #DashboardDevicesView .device {
      display: block !important; }
      #DashboardDevicesView .device .ant-col-lg-8 {
        width: 100%;
        margin-bottom: 30px; } }

#DashboardDevicesView .ant-table-row:hover td {
  background-color: #f9f9f9 !important;
  cursor: pointer; }

#DashboardDevicesView .ant-pagination-item-active {
  border-color: #78b829 !important; }

#DashboardDevicesView .ant-pagination-item-active a {
  color: #78b829 !important; }

#DashboardDevicesView .ant-input-group-addon {
  background-color: #78b829 !important;
  color: #fff !important; }

#DashboardDevicesView .ant-radio-group {
  width: 100% !important; }
  @media only screen and (max-width: 768px) {
    #DashboardDevicesView .ant-radio-group {
      margin-bottom: 30px;
      display: none; } }

#DashboardDevicesView .ant-menu-item-active:hover {
  color: #78b829 !important; }

#DashboardDevicesView .ant-radio-button-inner:hover {
  color: #78b829 !important; }

#DashboardDevicesView .ant-menu .ant-menu-sub .ant-menu-vertical .ant-menu-hidden li {
  color: #78b829 !important; }

#DashboardDevicesView .ant-radio-button-wrapper:hover span {
  color: #78b829 !important; }

#DashboardDevicesView .ant-radio-button-wrapper-checked {
  color: #fff !important;
  border-color: #78b829 !important;
  background-color: #78b829 !important;
  font-weight: bold; }

#DashboardDevicesView .ant-radio-button-wrapper-checked:hover span {
  color: #fff !important; }

#DashboardDevicesView .ant-radio-button-wrapper {
  border-color: #78b829 !important;
  width: 33.3%; }
  @media only screen and (max-width: 480px) {
    #DashboardDevicesView .ant-radio-button-wrapper {
      width: 33.3%; } }

#DashboardDevicesView input:focus,
#DashboardDevicesView input:hover {
  border-color: #78b829 !important;
  box-shadow: none !important; }

@media only screen and (max-width: 768px) {
  #DashboardDevicesView .col-btn-holder {
    text-align: center; } }

#DashboardDevicesView .column-button:hover {
  border-color: #78b829 !important;
  color: #78b829 !important; }

#DashboardDevicesView .ant-radio-button-wrapper {
  text-align: center !important;
  height: 35px;
  line-height: 35px; }

#DashboardDevicesView .ant-radio-button-wrapper-checked:first-child {
  border-color: #78b829 !important; }

#DashboardDevicesView input:hover,
#DashboardDevicesView input:focus {
  border-color: #78b829 !important;
  box-shadow: none; }

#DashboardDevicesView .ant-radio-button-wrapper:link {
  color: #78b829 !important; }

#DashboardDevicesView .ant-radio-button-wrapper:visited {
  color: #78b829 !important; }

#DashboardDevicesView .ant-radio-button-wrapper:focus {
  color: #78b829 !important; }

#DashboardDevicesView .ant-radio-button-wrapper:hover {
  color: #78b829 !important; }

#DashboardDevicesView .ant-radio-button-wrapper:active {
  color: #78b829 !important; }

#DashboardDevicesView .filter-icon {
  float: right;
  margin-top: 4px;
  color: #78b829;
  font-size: 17px; }
