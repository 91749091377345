/**
 *
 * UserView styles
 *
 */
#UserView {
  margin-top: 30px; }
  #UserView .users-container h2 {
    line-height: 24px;
    margin: 0; }
  #UserView .drop-down-company {
    width: 100% !important; }
  #UserView .company-container,
  #UserView .users-table {
    margin-top: 40px !important; }
  #UserView .ant-select-selection {
    height: 35px; }
  #UserView .ant-select-selection:hover {
    border-color: #78b829; }
  #UserView .ant-select-selection__rendered {
    line-height: 34px; }
  #UserView .ant-input {
    height: 35px; }
  #UserView .ant-input:hover,
  #UserView .ant-input:focus {
    border-color: #78b829 !important;
    box-shadow: none; }
  #UserView .ant-table-row:hover td {
    background-color: #78b829 !important;
    color: #fff !important; }
  #UserView .ant-pagination-item-active {
    border-color: #78b829 !important; }
  #UserView .ant-pagination-item-active a {
    color: #78b829 !important; }
  @media only screen and (max-width: 768px) {
    #UserView .company-container label {
      min-width: 100px;
      display: inline-block; }
    #UserView .company-container .ant-col-lg-7,
    #UserView .company-container .ant-col-lg-10 {
      margin-bottom: 20px; } }
  #UserView .ant-select-focused .ant-select-selection,
  #UserView .ant-select-selection:focus,
  #UserView .ant-select-selection:active {
    border-color: #78b829;
    box-shadow: 0 0 0 2px rgba(120, 184, 41, 0.2); }
  #UserView .add-company-button {
    background-color: transparent !important;
    color: #78b829 !important;
    border: 0;
    float: right !important;
    font-size: 28px;
    margin-bottom: 0;
    box-shadow: 0 0 0 2px rgba(120, 184, 41, 0);
    height: 31px;
    padding-right: 0;
    line-height: 21px; }
  #UserView .filter-holder .ant-input-group-addon {
    background: #78b829 !important; }
    #UserView .filter-holder .ant-input-group-addon i {
      color: #fff; }

.ant-popover-buttons .ant-btn-primary {
  background-color: #78b829;
  border-color: #78b829; }

.ant-popover-buttons .ant-btn-primary:hover {
  background-color: #78b829 !important; }
