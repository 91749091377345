/**
 *
 * MyHeader styles
 *
 */
#MyHeader {
  background-color: #f8f8f8 !important;
  height: auto;
  padding: 5px 20px; }
  #MyHeader .active-route {
    font-weight: bold !important;
    color: #78b829 !important; }
  #MyHeader .logo-inner {
    max-width: 125px;
    position: relative; }
    @media only screen and (max-width: 768px) {
      #MyHeader .logo-inner {
        max-width: 195px; } }
  #MyHeader .tsenso-logo {
    width: 100%;
    cursor: pointer; }
  #MyHeader .wifi-logo {
    color: #78b829 !important;
    font-size: 25px !important;
    cursor: pointer;
    position: absolute;
    top: 5;
    right: -47px;
    z-index: 11; }
  #MyHeader .ant-menu {
    background: transparent !important;
    display: inline-block; }
  #MyHeader .ant-menu-horizontal {
    border-bottom: transparent !important; }
  #MyHeader .ant-menu-item-active {
    border-bottom: 2px solid #78b829 !important;
    color: #78b829 !important; }
  #MyHeader .ant-menu-submenu-active {
    border-bottom: 2px solid #78b829 !important;
    color: #78b829 !important; }
  #MyHeader .ant-menu-submenu-active div {
    color: #78b829 !important; }
  #MyHeader .ant-menu-submenu-title {
    padding: 0; }
  #MyHeader .submenu-title-wrapper i {
    color: #78b829 !important; }
  #MyHeader .submenu-title-wrapper .anticon-user {
    border: 1px solid #78b829;
    border-radius: 100%;
    padding: 5px;
    font-size: 20px;
    margin-left: 0;
    vertical-align: middle;
    margin-right: 0; }
  #MyHeader .ant-menu-item > a:hover {
    color: #78b829 !important; }
  #MyHeader .main-navigation {
    margin-right: 10px; }
    @media only screen and (max-width: 768px) {
      #MyHeader .main-navigation {
        text-align: left; } }
    #MyHeader .main-navigation li {
      padding: 0 20px !important; }
      @media only screen and (max-width: 768px) {
        #MyHeader .main-navigation li {
          padding: 0 !important; } }
    #MyHeader .main-navigation li:nth-last-child(2) {
      display: none; }
      @media only screen and (max-width: 768px) {
        #MyHeader .main-navigation li:nth-last-child(2) {
          display: block; } }
    #MyHeader .main-navigation .anticon-caret-down {
      margin-left: 5px;
      margin-right: 0;
      vertical-align: middle; }
    @media only screen and (max-width: 768px) {
      #MyHeader .main-navigation .ant-menu-overflowed-submenu span {
        width: 40px;
        background-image: url("../../assets/images/trigger-icon.png");
        background-repeat: no-repeat;
        background-size: 100%;
        display: inline-block;
        font-size: 0;
        margin-top: 7px; } }
  #MyHeader .main-nav-holder {
    text-align: right; }
    @media only screen and (max-width: 768px) {
      #MyHeader .main-nav-holder .main-navigation {
        margin: 7px 0 0;
        width: 40px; } }
  #MyHeader .ant-menu-horizontal > .ant-menu-item:hover,
  #MyHeader .ant-menu-horizontal > .ant-menu-submenu:hover,
  #MyHeader .ant-menu-horizontal > .ant-menu-item-active,
  #MyHeader .ant-menu-horizontal > .ant-menu-submenu-active,
  #MyHeader .ant-menu-horizontal > .ant-menu-item-open,
  #MyHeader .ant-menu-horizontal > .ant-menu-submenu-open,
  #MyHeader .ant-menu-horizontal > .ant-menu-item-selected,
  #MyHeader .ant-menu-horizontal > .ant-menu-submenu-selected {
    color: #78b829;
    border-color: #78b829; }
  #MyHeader .user-account {
    margin-top: 10px; }
    @media only screen and (max-width: 768px) {
      #MyHeader .user-account {
        display: none; } }
  #MyHeader .wifi-details {
    position: absolute;
    top: 8px;
    right: -53px;
    color: #78b829;
    font-size: 10px; }

.user-name {
  pointer-events: none;
  opacity: 0.5;
  margin-bottom: 0 !important; }

.ant-menu-item > a:hover {
  color: #78b829 !important; }

@media only screen and (max-width: 768px) {
  .ant-menu-submenu-vertical-left .ant-menu-submenu-title .anticon-caret-down {
    display: none !important; } }

.ant-menu-submenu-vertical-left .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical-left .ant-menu-submenu-arrow:after {
  background: #78b829 !important; }

.ant-menu-submenu-active .submenu-title-wrapper {
  color: #78b829 !important; }

.ant-menu-submenu-active .ant-menu-submenu-arrow {
  color: #78b829 !important; }

.ant-menu-item .anticon {
  vertical-align: 0 !important; }
