/**
 *
 * CreateDeliveryTaskView styles
 *
 */
#CreateDeliveryTaskView {
  margin-top: 4% !important; }
  #CreateDeliveryTaskView .ant-row {
    margin-bottom: 30px; }
  #CreateDeliveryTaskView .ant-card-head {
    background-color: #fafafa; }
  @media only screen and (max-width: 768px) {
    #CreateDeliveryTaskView .to-address-container {
      margin-top: 30px; } }
  #CreateDeliveryTaskView .ant-btn-primary,
  #CreateDeliveryTaskView .ant-btn-primary:active,
  #CreateDeliveryTaskView .ant-btn-primary.active {
    background-color: #78b829 !important;
    border-color: #78b829 !important; }
  #CreateDeliveryTaskView .ant-form-item {
    margin-bottom: 0px !important; }
  #CreateDeliveryTaskView .ant-input-number {
    width: 100% !important; }
  #CreateDeliveryTaskView .add-box-container,
  #CreateDeliveryTaskView .schedule-container,
  #CreateDeliveryTaskView .add-task-container,
  #CreateDeliveryTaskView .add-item-card {
    margin-top: 2% !important; }
  #CreateDeliveryTaskView .add-item-button {
    margin-top: 2% !important; }
  #CreateDeliveryTaskView .add-item-button,
  #CreateDeliveryTaskView .add-task-button {
    background-color: #78b829 !important;
    border: #78b829 !important;
    color: #fff !important; }
  #CreateDeliveryTaskView .ant-select,
  #CreateDeliveryTaskView .ant-select-enabled {
    width: 100% !important; }
  #CreateDeliveryTaskView .ant-checkbox-wrapper .ant-checkbox-inner {
    border-color: #78b829 !important; }
  #CreateDeliveryTaskView .ant-checkbox-wrapper-checked .ant-checkbox-inner {
    background-color: #78b829 !important;
    border-color: #78b829 !important; }
  #CreateDeliveryTaskView .add-task-button {
    min-width: 30% !important;
    height: 40px !important;
    font-size: 16px; }
  #CreateDeliveryTaskView .add-task-container {
    margin-bottom: 2% !important; }
  #CreateDeliveryTaskView .ant-card,
  #CreateDeliveryTaskView .add-task-container,
  #CreateDeliveryTaskView .ant-card-bordered,
  #CreateDeliveryTaskView .ant-card-wider-padding {
    padding: 0px !important; }
  #CreateDeliveryTaskView .dynamic-delete-button {
    margin-top: 1% !important;
    color: #78b829 !important;
    font-size: 40px;
    text-align: center !important; }
  #CreateDeliveryTaskView .ant-table-row:hover td {
    background-color: #f9f9f9 !important;
    color: #fff !important; }
  #CreateDeliveryTaskView .ant-pagination-item-active {
    border-color: #78b829 !important; }
  #CreateDeliveryTaskView .ant-pagination-item-active a {
    color: #78b829 !important; }
