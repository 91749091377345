/**
 *
 * DashboardMonitoringsView styles
 *
 */
#DashboardMonitoringsView .sorting-icon {
  float: right;
  width: 12px;
  vertical-align: middle;
  cursor: pointer;
  opacity: 0.3;
  margin-top: 2px; }
  #DashboardMonitoringsView .sorting-icon:hover {
    opacity: 0.6; }

#DashboardMonitoringsView .add-monitoring-button {
  background-color: transparent !important;
  color: #78b829 !important;
  border: 0;
  float: right !important;
  font-size: 28px;
  margin-bottom: 0;
  box-shadow: 0 0 0 2px rgba(120, 184, 41, 0);
  height: 35px;
  line-height: 17px;
  padding-right: 0; }
  @media only screen and (max-width: 480px) {
    #DashboardMonitoringsView .add-monitoring-button {
      padding: 0; } }

#DashboardMonitoringsView .radio-buttons {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }
  @media only screen and (max-width: 768px) {
    #DashboardMonitoringsView .radio-buttons {
      display: none; } }

#DashboardMonitoringsView .ant-radio-button-wrapper:not(:first-child)::before {
  background-color: #78b829 !important;
  width: 0 !important; }

#DashboardMonitoringsView .ant-table-pagination.ant-pagination {
  margin-bottom: 40px; }

#DashboardMonitoringsView .ant-pagination-item-active {
  border-color: #78b829 !important; }

#DashboardMonitoringsView .ant-pagination-item-active a {
  color: #78b829 !important; }

#DashboardMonitoringsView .monitoring-name {
  margin-bottom: 35px !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important; }
  @media only screen and (max-width: 480px) {
    #DashboardMonitoringsView .monitoring-name {
      display: block !important;
      margin-bottom: 10px; }
      #DashboardMonitoringsView .monitoring-name .field-holder {
        margin-bottom: 20px; } }

#DashboardMonitoringsView .ant-table-row:hover td {
  background-color: #f9f9f9 !important;
  cursor: pointer; }

#DashboardMonitoringsView .ant-table-row td {
  border-left: 1px solid #f9f9f9; }
  #DashboardMonitoringsView .ant-table-row td :first-child {
    border: 0; }

#DashboardMonitoringsView .ant-input-group-addon {
  background-color: #78b829 !important;
  color: #fff !important;
  padding: 0 20px; }

#DashboardMonitoringsView .ant-radio-group {
  width: 100% !important;
  display: inline-block; }

#DashboardMonitoringsView .ant-menu-item-active:hover {
  color: #78b829 !important; }

#DashboardMonitoringsView .ant-radio-button-inner:hover {
  color: #78b829 !important; }

#DashboardMonitoringsView .column-button:hover {
  border-color: #78b829 !important;
  color: #78b829 !important; }

#DashboardMonitoringsView .monitoring-input {
  max-width: 100%; }

#DashboardMonitoringsView .monitoring-input:focus,
#DashboardMonitoringsView .monitoring-input:hover {
  border-color: #78b829 !important;
  box-shadow: none !important; }

#DashboardMonitoringsView .ant-menu .ant-menu-sub .ant-menu-vertical .ant-menu-hidden li {
  color: #78b829 !important; }

#DashboardMonitoringsView .ant-radio-button-wrapper:hover span {
  color: #78b829 !important; }

#DashboardMonitoringsView .ant-radio-button-wrapper-checked {
  color: #fff !important;
  border-color: #78b829 !important;
  background-color: #78b829 !important;
  font-weight: bold; }

#DashboardMonitoringsView .ant-radio-button-wrapper-checked:hover span {
  color: #fff !important; }

#DashboardMonitoringsView .ant-radio-button-wrapper {
  border-color: #78b829 !important;
  width: 33.3%; }
  @media only screen and (max-width: 768px) {
    #DashboardMonitoringsView .ant-radio-button-wrapper {
      width: 33.3%; } }

#DashboardMonitoringsView .ant-radio-button-wrapper {
  text-align: center !important;
  height: 35px;
  line-height: 35px; }

#DashboardMonitoringsView .ant-radio-button-wrapper-checked:first-child {
  border-color: #78b829 !important; }

#DashboardMonitoringsView .ant-checkbox,
#DashboardMonitoringsView .ant-checkbox-checked {
  background-color: #78b829 !important; }

@media only screen and (max-width: 768px) {
  #DashboardMonitoringsView .crtical-holder {
    margin: 20px 0; } }

#DashboardMonitoringsView .ant-table-content {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  border-bottom: 0; }
  #DashboardMonitoringsView .ant-table-content p {
    margin: 0; }

#DashboardMonitoringsView .monitoring-name .col-btn-holder {
  text-align: center; }

#DashboardMonitoringsView .monitoring-name .ant-input {
  height: 35px; }

#DashboardMonitoringsView .monitoring-name .ant-input:hover,
#DashboardMonitoringsView .monitoring-name .ant-input:focus {
  border-color: #78b829;
  box-shadow: 0 0 0 2px rgba(120, 184, 41, 0.2); }

#DashboardMonitoringsView .monitoring-name .filter-icon {
  float: right;
  margin-top: 4px;
  color: #78b829;
  font-size: 17px; }

#DashboardMonitoringsView .monitoring-name .column-button {
  height: 35px;
  float: right; }
  @media only screen and (max-width: 480px) {
    #DashboardMonitoringsView .monitoring-name .column-button {
      float: left; } }

#DashboardMonitoringsView .select-holder {
  margin-bottom: 20px;
  margin-top: 30px; }
  #DashboardMonitoringsView .select-holder .ant-select {
    width: 100% !important; }
    @media only screen and (max-width: 768px) {
      #DashboardMonitoringsView .select-holder .ant-select {
        margin-bottom: 20px; } }
  #DashboardMonitoringsView .select-holder .last-select {
    margin-bottom: 0; }
  #DashboardMonitoringsView .select-holder .ant-select:first-child {
    margin-left: 0; }
  #DashboardMonitoringsView .select-holder .ant-select-selection {
    border: 1px solid #78b829 !important;
    height: 35px; }

#DashboardMonitoringsView .asset-type-dropdown {
  width: 100% !important; }

#DashboardMonitoringsView .select-asset-holder .ant-select-selection {
  height: 35px;
  border-color: #78b829; }
  #DashboardMonitoringsView .select-asset-holder .ant-select-selection .ant-select-selection__rendered {
    height: 35px; }

#DashboardMonitoringsView .ant-select-focused .ant-select-selection,
#DashboardMonitoringsView .ant-select-selection:focus,
#DashboardMonitoringsView .ant-select-selection:active {
  box-shadow: 0 0 0 2px rgba(120, 184, 41, 0.2); }

#DashboardMonitoringsView .ant-select-selection-selected-value {
  line-height: 35px; }

#DashboardMonitoringsView .ant-select-selection__placeholder {
  color: rgba(0, 0, 0, 0.65); }

#DashboardMonitoringsView .ant-radio-button-wrapper:hover {
  color: #78b829 !important; }

#DashboardMonitoringsView .critical {
  background-color: red !important; }

.ant-select-dropdown-menu-item:hover {
  background-color: #78b829 !important;
  color: #fff; }

.ant-select-dropdown-menu-item-active {
  background-color: #78b829 !important;
  color: #fff; }
