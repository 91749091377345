/**
 *
 * StartMonitoring styles
 *
 */
#StartMonitoring {
  margin-top: 3% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }
  #StartMonitoring .ant-form {
    width: 70% !important; }
  #StartMonitoring .label {
    height: 50px !important;
    line-height: 50px !important;
    font-size: 16px !important; }
  #StartMonitoring .ant-select {
    width: 100% !important; }
  #StartMonitoring .footer-btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 20px !important; }
  #StartMonitoring .monitoring-wizard-card {
    margin: 0 auto 40px;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.1); }
    @media only screen and (max-width: 768px) {
      #StartMonitoring .monitoring-wizard-card {
        max-width: 100%; } }
  #StartMonitoring .ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled) {
    box-shadow: 0 0 0 2px rgba(120, 184, 41, 0.2) !important;
    border-color: #78b829 !important; }
  #StartMonitoring .ant-form-explain {
    text-align: left; }
  #StartMonitoring .icons {
    font-size: 22px !important;
    color: #78b829 !important; }
  #StartMonitoring .right-icon {
    float: right !important; }
  #StartMonitoring .steps-header h2 {
    margin-bottom: 10px;
    line-height: 21px;
    font-weight: bold; }
    @media only screen and (max-width: 480px) {
      #StartMonitoring .steps-header h2 {
        font-size: 20px; } }
  #StartMonitoring .ant-steps {
    margin-bottom: 40px;
    margin-top: 20px; }
  #StartMonitoring .ant-steps-item-process .ant-steps-item-icon {
    background: #78b829;
    border-color: #78b829; }
  #StartMonitoring .steps-action {
    overflow: hidden;
    text-align: center; }
    #StartMonitoring .steps-action .steps-btn {
      background: #78b829;
      border-color: #78b829;
      margin-top: 20px;
      height: 40px;
      display: inline-block;
      width: 30%; }
      @media only screen and (max-width: 480px) {
        #StartMonitoring .steps-action .steps-btn {
          width: 100%; } }
    #StartMonitoring .steps-action .steps-btn:hover {
      color: #fff; }
  #StartMonitoring .ant-input {
    height: 40px; }
  #StartMonitoring .ant-input:hover,
  #StartMonitoring .ant-select-selection:hover {
    border-color: #78b829 !important;
    box-shadow: 0 0 0 2px rgba(120, 184, 41, 0.2); }
  #StartMonitoring .ant-select-selection,
  #StartMonitoring .ant-select-selection__rendered {
    height: 40px; }
  #StartMonitoring p {
    margin-bottom: 15px; }
  #StartMonitoring .ant-steps-item-finish .ant-steps-item-icon {
    border-color: #78b829;
    line-height: 22px; }
    #StartMonitoring .ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon {
      color: #78b829; }
  #StartMonitoring .ant-steps-item-finish .ant-steps-item-content > .ant-steps-item-title:after {
    background: #78b829; }
  #StartMonitoring .select-holder .ant-select-selection .ant-select-selection-selected-value {
    line-height: 36px; }
  #StartMonitoring .ant-select-open .ant-select-selection {
    border-color: #78b829;
    box-shadow: 0 0 0 2px rgba(120, 184, 41, 0.2); }
  #StartMonitoring .submit-btn-holder {
    text-align: center; }
    #StartMonitoring .submit-btn-holder .ant-btn-primary {
      background: #78b829;
      border-color: #78b829;
      float: right;
      height: 40px; }
  #StartMonitoring .check-box-align {
    float: left; }
  #StartMonitoring .bottom-btn-holder {
    margin-bottom: 20px; }
  #StartMonitoring .msg-box {
    margin-bottom: 20px; }
  #StartMonitoring .photo-btn-holder {
    text-align: center; }
  #StartMonitoring .align-btn {
    float: right !important; }
    @media only screen and (max-width: 480px) {
      #StartMonitoring .align-btn {
        float: none !important;
        display: inline-block;
        margin-bottom: 10px; } }
  #StartMonitoring .photo-btn {
    min-width: 150px;
    float: left;
    margin-bottom: 10px; }
    @media only screen and (max-width: 480px) {
      #StartMonitoring .photo-btn {
        float: none; } }
    #StartMonitoring .photo-btn i {
      vertical-align: middle;
      margin-top: -4px; }
  #StartMonitoring .photo-btn:hover {
    color: #78b829;
    border-color: #78b829; }
  #StartMonitoring .photo-btn:focus {
    color: #78b829; }
  #StartMonitoring .start-now-holder {
    text-align: center;
    margin: 20px 0 50px 0; }
    #StartMonitoring .start-now-holder .ant-btn-primary {
      background: #78b829;
      border-color: #78b829;
      display: inline-block;
      height: 40px; }
  #StartMonitoring .date-picker {
    margin-bottom: 20px; }
    @media only screen and (max-width: 480px) {
      #StartMonitoring .date-picker {
        width: 100% !important; } }
  #StartMonitoring .webcam img {
    max-width: 500px; }
    @media only screen and (max-width: 768px) {
      #StartMonitoring .webcam img {
        max-width: 100%; } }
  @media only screen and (max-width: 480px) {
    #StartMonitoring .ant-steps-horizontal.ant-steps-label-horizontal {
      display: flex; } }
  @media only screen and (max-width: 480px) {
    #StartMonitoring .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item:not(:last-child) > .ant-steps-item-tail {
      display: none; } }
  @media only screen and (max-width: 480px) {
    #StartMonitoring .ant-steps-item-finish .ant-steps-item-title {
      background: #78b829; } }
  @media only screen and (max-width: 480px) {
    #StartMonitoring .ant-steps-item-title {
      height: 1px;
      background: #f3f3f3;
      width: 100%;
      margin-top: 18px; } }
  #StartMonitoring .ant-input:focus {
    box-shadow: 0 0 1px #78b829 !important;
    border-color: #78b829; }
  #StartMonitoring .uploading-image {
    margin-top: 2% !important; }
  #StartMonitoring .ant-select-selection-selected-value {
    line-height: 37px; }
  #StartMonitoring .ant-spin-dot i {
    background-color: #78b829; }

.circle-scheduled {
  font-size: 24px !important;
  border: 1px solid #78b829 !important;
  border-radius: 50%; }

.webcam {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important; }

.steps-btn-row {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important; }

.steps-btn {
  background: #78b829 !important;
  color: #fff !important;
  border-color: #78b829 !important;
  margin-top: 20px !important;
  height: 40px !important;
  display: inline-block !important;
  width: 30% !important; }
  @media only screen and (max-width: 480px) {
    .steps-btn {
      width: 100% !important; } }

.steps-btn:hover {
  color: #fff !important; }
