/**
 *
 * AboutCard styles
 *
 */
#AboutCard {
  margin-top: 3% !important; }
  #AboutCard .ant-card-head {
    background: #3F3F3F !important; }
  #AboutCard .ant-card-head-title {
    color: #fff !important; }
