/**
 *
 * MonitoringWarenView styles
 *
 */
#MonitoringWarenView {
  margin-top: 40px !important; }
  #MonitoringWarenView .ant-card-head {
    background: #3F3F3F !important; }
  #MonitoringWarenView .status-card .ant-calendar-picker-input.ant-input {
    padding-left: 15px; }
  #MonitoringWarenView .status-card .start-button {
    margin-left: 20px; }
  #MonitoringWarenView .mb-holder .mb-card {
    margin-bottom: 30px; }
    @media only screen and (max-width: 480px) {
      #MonitoringWarenView .mb-holder .mb-card {
        margin-bottom: 0; } }
  #MonitoringWarenView .mb-holder .ant-card-bordered {
    height: 100%; }
  @media only screen and (max-width: 768px) {
    #MonitoringWarenView .mb-holder .ant-col-lg-12 {
      width: 50%; } }
  @media only screen and (max-width: 480px) {
    #MonitoringWarenView .mb-holder .ant-col-lg-12 {
      width: 100%;
      margin-bottom: 30px; } }
  #MonitoringWarenView .ant-card-head-title {
    color: #fff !important; }
  #MonitoringWarenView .row-margin {
    margin-top: 3% !important; }
  #MonitoringWarenView .display-flex {
    display: flex !important;
    flex-direction: row;
    justify-content: space-between !important; }
    @media only screen and (max-width: 480px) {
      #MonitoringWarenView .display-flex {
        display: block !important;
        margin-bottom: 20px !important; }
        #MonitoringWarenView .display-flex .ant-col-lg-11 {
          margin-bottom: 20px; } }
  #MonitoringWarenView .label {
    margin-top: 8px !important; }
  #MonitoringWarenView .row-label {
    margin-top: 20px !important; }
  #MonitoringWarenView .input-number,
  #MonitoringWarenView .date-picker {
    width: 100% !important; }
  #MonitoringWarenView .label-color {
    color: darkgreen !important; }
  #MonitoringWarenView .button-container {
    margin-top: 2% !important; }
    #MonitoringWarenView .button-container .ant-btn-primary {
      width: 30% !important;
      color: #fff !important;
      background-color: #78b829 !important;
      border-color: #78b829 !important;
      height: 40px; }
      @media only screen and (max-width: 768px) {
        #MonitoringWarenView .button-container .ant-btn-primary {
          width: 100% !important; } }
    #MonitoringWarenView .button-container .ant-btn-primary:hover {
      color: #fff !important;
      background-color: #78b829 !important;
      border-color: #78b829 !important; }
  #MonitoringWarenView .monitoring-report-container {
    margin-top: 2% !important;
    margin-bottom: 2% !important; }
  #MonitoringWarenView .report-buttons-container {
    margin-top: 5% !important; }
    @media only screen and (max-width: 768px) {
      #MonitoringWarenView .report-buttons-container {
        display: block !important; }
        #MonitoringWarenView .report-buttons-container .ant-col-lg-8 {
          margin-bottom: 15px; } }
    @media only screen and (max-width: 768px) {
      #MonitoringWarenView .report-buttons-container .report-button {
        width: 100%; } }
  #MonitoringWarenView .report-button {
    color: #fff !important;
    background-color: #78b829 !important;
    border-color: #78b829 !important;
    height: 40px;
    width: 100%; }
  #MonitoringWarenView .end-monitoring-button {
    width: 100% !important;
    color: #fff !important;
    background-color: red !important;
    border-color: #78b829 !important;
    height: 40px;
    border-color: red !important; }
    @media only screen and (max-width: 768px) {
      #MonitoringWarenView .end-monitoring-button {
        width: 100% !important; } }
  #MonitoringWarenView .stop-m-holder {
    margin-top: 30px; }
  #MonitoringWarenView .start-button {
    background-color: #78b829 !important;
    color: #fff !important;
    width: 100%;
    height: 40px;
    display: inline-block; }
    @media only screen and (max-width: 768px) {
      #MonitoringWarenView .start-button {
        width: 100% !important; } }
  #MonitoringWarenView input,
  #MonitoringWarenView .input-number {
    height: 40px; }
  #MonitoringWarenView .input-number:hover,
  #MonitoringWarenView input:hover {
    border-color: #78b829; }
  #MonitoringWarenView input:hover,
  #MonitoringWarenView input:focus,
  #MonitoringWarenView .ant-input-number-input:focus {
    border-color: #78b829;
    box-shadow: 0 0 0 2px rgba(120, 184, 41, 0.2); }
  #MonitoringWarenView .temperature-card label {
    line-height: 34px; }
  #MonitoringWarenView .temperature-card .ant-input {
    line-height: 30px;
    height: 40px; }
  #MonitoringWarenView .ant-calendar-picker .ant-calendar-picker-input.ant-input {
    padding: 0;
    height: 40px; }
  #MonitoringWarenView .ant-calendar-range-picker-separator {
    line-height: 40px; }
  #MonitoringWarenView .ant-calendar-picker:hover
.ant-calendar-picker-input:not(.ant-input-disabled) {
    border-color: #78b829; }
  #MonitoringWarenView .started-holder {
    text-align: center; }
  #MonitoringWarenView .safe {
    color: darkgreen !important; }
  #MonitoringWarenView .critical {
    color: red !important; }
  #MonitoringWarenView .ant-card-head-title {
    font-weight: lighter !important; }
  #MonitoringWarenView .text-label {
    margin-top: 10% !important; }
  #MonitoringWarenView .started-date {
    margin-bottom: 2% !important; }
  #MonitoringWarenView .started-ended-date-container {
    margin-bottom: 2% !important; }
  #MonitoringWarenView .row-container {
    margin-top: 5% !important; }
  #MonitoringWarenView .sensor_img {
    height: 100%;
    width: 100%;
    max-width: 250px;
    margin: 0 auto; }
  #MonitoringWarenView .ant-card-wider-padding {
    text-align: center; }
