/**
 *
 * MyForm styles
 *
 */
#MyForm {
  margin-top: 40px;
  margin-bottom: 17vh; }
  #MyForm .header-text {
    color: #fff !important;
    width: 80%;
    text-align: center;
    font-family: "Overlock", cursive; }
  #MyForm .welcome-text {
    text-align: center !important;
    font-size: 25px;
    margin: 0;
    color: #181818;
    position: relative;
    font-weight: bold;
    text-transform: uppercase; }
  #MyForm #FormCard {
    width: 60% !important;
    margin-top: 45px;
    overflow: hidden;
    border-radius: 5px; }
    @media only screen and (max-width: 480px) {
      #MyForm #FormCard {
        width: 100% !important; } }
    #MyForm #FormCard .forgot-password {
      color: #78b829 !important;
      display: block;
      margin-bottom: 10px; }
      @media only screen and (max-width: 768px) {
        #MyForm #FormCard .forgot-password {
          margin-bottom: 10px;
          display: block; } }
    #MyForm #FormCard .form-right-bg {
      padding: 30px 0;
      position: relative; }
    #MyForm #FormCard .overlay {
      background: rgba(0, 0, 0, 0.6);
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      position: absolute; }
    #MyForm #FormCard .login-form {
      padding-top: 30px;
      align-items: center;
      width: 80%;
      margin: 0 auto;
      position: relative; }
      @media only screen and (max-width: 768px) {
        #MyForm #FormCard .login-form {
          margin: 0 auto; } }
    #MyForm #FormCard .logo-holder {
      height: 100%; }
    #MyForm #FormCard .login-left-bg {
      margin: 142px auto 0;
      max-width: 176px; }
    #MyForm #FormCard .login-form input {
      height: 50px;
      font-family: "Overlock", cursive; }
    #MyForm #FormCard .login-form-button {
      background-color: #78b829;
      border-color: #78b829;
      height: 50px;
      width: 100%; }
    #MyForm #FormCard .form-heading {
      text-align: center;
      color: #78b829;
      font-family: "Overlock", cursive;
      font-size: 24px; }
    #MyForm #FormCard .ant-checkbox-wrapper span {
      color: #78b829;
      font-family: "Overlock", cursive; }
    #MyForm #FormCard .footer-link {
      width: 100%;
      text-align: center;
      margin-top: 10px; }
    #MyForm #FormCard .footer-link a {
      text-decoration: none;
      color: #78b829;
      font-size: 12px;
      font-family: "Overlock", cursive; }
    #MyForm #FormCard .footer-text {
      text-align: center;
      font-size: 10px; }
    #MyForm #FormCard .footer-text a {
      text-decoration: none;
      color: #a9a9a9;
      font-family: "Overlock", cursive; }
    #MyForm #FormCard .footer-link p {
      color: #78b829 !important;
      font-family: "Overlock", cursive;
      cursor: pointer; }
  #MyForm .ant-card-body {
    padding: 0; }
  #MyForm .cap-center {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 0; }
  #MyForm .left-sec-bg {
    background-image: url(../../assets/images/img01.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    min-height: 400px;
    display: flex; }
    @media only screen and (max-width: 768px) {
      #MyForm .left-sec-bg {
        min-height: auto;
        display: none; } }
  #MyForm .left-side-cap {
    position: relative;
    width: 100%; }
    #MyForm .left-side-cap h2 {
      color: #fff;
      font-size: 25px;
      margin: 20px 30px 0;
      color: #78b829;
      font-weight: bold;
      text-align: center; }
