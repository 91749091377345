/**
 *
 * ProductView styles
 *
 */
#ProductView {
  margin-top: 4% !important; }
  #ProductView .ant-pagination-item-active {
    border-color: #78b829 !important; }
  #ProductView .ant-pagination-item-active a {
    color: #78b829 !important; }
  #ProductView .ant-table-row:hover td {
    background-color: #f9f9f9 !important; }
  #ProductView .column-button {
    float: right !important;
    margin-bottom: 4% !important; }
  #ProductView .column-button:hover {
    border-color: #78b829 !important;
    color: #78b829 !important; }
  #ProductView .ant-table-content {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    border-bottom: 0; }
