/**
 *
 * AddCompanyCard styles
 *
 */
#AddCompanyCard {
  margin-top: 40px !important; }
  #AddCompanyCard .login-form .add-form-button {
    background-color: #78b829 !important;
    color: #fff !important;
    border-color: #78b829 !important;
    width: 100% !important;
    height: 40px !important;
    font-size: 16px !important; }
  #AddCompanyCard .login-form .ant-input {
    height: 40px !important; }
  #AddCompanyCard .ant-select-selection:hover {
    border-color: #78b829; }
  #AddCompanyCard .ant-select-selection,
  #AddCompanyCard .ant-select-selection__rendered {
    height: 40px; }
  #AddCompanyCard .ant-select-selection-selected-value {
    line-height: 38px;
    background-position: 1px 10px !important; }
  #AddCompanyCard .ant-select-selection__placeholder,
  #AddCompanyCard .ant-select-selection-selected-value {
    padding-left: 30px;
    background-image: url("../../assets/images/country-icon.png") !important;
    background-repeat: no-repeat;
    background-size: 17px;
    background-position: 1px 1px; }
  #AddCompanyCard input,
  #AddCompanyCard .input-number {
    height: 40px; }
  #AddCompanyCard input:hover {
    border-color: #78b829; }
  #AddCompanyCard input:hover,
  #AddCompanyCard input:focus,
  #AddCompanyCard .ant-input-number-input:focus {
    border-color: #78b829;
    box-shadow: 0 0 0 2px rgba(120, 184, 41, 0.2); }
  #AddCompanyCard .ant-form-explain {
    margin-top: 6px; }
