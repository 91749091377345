/**
 *
 * AddLocationCard styles
 *
 */
#AddLocationCard {
  margin-top: 40px !important; }
  #AddLocationCard .login-form .add-form-button {
    background-color: #78b829 !important;
    color: #fff !important;
    border-color: #78b829 !important;
    width: 100% !important;
    height: 40px !important;
    font-size: 16px !important; }
  #AddLocationCard .login-form .ant-input {
    height: 40px !important; }
  #AddLocationCard .ant-select-selection:hover {
    border-color: #78b829; }
  #AddLocationCard .ant-select-selection,
  #AddLocationCard .ant-select-selection__rendered {
    height: 40px; }
  #AddLocationCard .ant-select-selection-selected-value {
    line-height: 38px;
    background-position: 1px 10px !important; }
  #AddLocationCard .ant-select-selection__placeholder,
  #AddLocationCard .ant-select-selection-selected-value {
    padding-left: 30px;
    background-image: url("../../assets/images/country-icon.png") !important;
    background-repeat: no-repeat;
    background-size: 17px;
    background-position: 1px 1px; }
  #AddLocationCard input,
  #AddLocationCard .input-number {
    height: 40px; }
  #AddLocationCard input:hover {
    border-color: #78b829; }
  #AddLocationCard input:hover,
  #AddLocationCard input:focus,
  #AddLocationCard .ant-input:hover,
  #AddLocationCard .ant-input:focus,
  #AddLocationCard .ant-input-number-input:focus {
    border-color: #78b829 !important;
    box-shadow: 0 0 0 2px rgba(120, 184, 41, 0.2) !important;
    outline: none; }
  #AddLocationCard #address {
    box-sizing: border-box;
    padding: 4px 11px 4px 42px;
    height: 40px;
    font-size: 14px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.5);
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    width: 100%; }
  #AddLocationCard .address-icon {
    position: absolute;
    top: -13px;
    font-size: 17px;
    left: 14px;
    color: #c7c7c7; }
